<template>
  <div>
    <cp-head-table
      :title="$t('issuerEmailTemplates.title')"
      title-icon="ios-build"
    />
    <cp-table
      ref="cpTable"
      get-data-action="issuerEmailTemplates/getIssuerEmailTemplates"
      :url-params="urlParams"
      :fields="fields"
    >
      <template
        slot="status"
        slot-scope="{ rowData }"
      >
        <b-badge :variant="getActivationStatusColor(rowData.item.enabled)">
          {{ getActivationStatusLabel(rowData.item.enabled) }}
        </b-badge>
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.edit')"
          @click="toEditModal(rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.edit') }}
        </b-btn>
        <b-btn
          variant="default btn-xs md-btn-flat"
          :title="getActivationStatus(rowData.item.enabled)"
          @click="updateEmailStatus(rowData.item)"
        >
          <i class="ion ion-md-switch" />
          {{ getActivationStatus(rowData.item.enabled) }}
        </b-btn>
      </template>
      <template
        slot="HEAD_actions"
      >
        <b-btn
          class="deactivate-all-btn"
          variant="default btn-primary btn-sm"
          :title="getAllActivationStatus()"
          @click="updateAllEmailStatus()"
        >
          <i class="ion ion-md-switch" />
          {{ getAllActivationStatus() }}
        </b-btn>
      </template>
    </cp-table>

    <cp-delete-modal
      ref="cpDeleteAlert"
      :item-info="deleteItem"
      @delete-item="removeItem"
    />

    <cp-email-modal
      ref="CpIssuerEmail"
      :email-data="emailData"
      @onSubmit="saveChanges"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import CpTable from '~/components/shared/cp-table';
import CpHeadTable from '~/components/common/head';
import CpDeleteModal from '~/components/common/table/deleteModal';
import CpEmailModal from './issuer-email-modal';

export default {
  name: 'IssuerEmailTemplates',

  metaInfo: {
    title: 'Issuer Email templates',
  },

  components: {
    CpTable,
    CpHeadTable,
    CpDeleteModal,
    CpEmailModal,
  },

  data() {
    return {
      emailData: {},
      deleteItem: null,
      fields: [
        { key: 'name', i18nKey: 'issuerEmailTemplates.label.table.name', tdClass: 'align-middle' },
        { key: 'status', i18nKey: 'issuerEmailTemplates.label.table.status', tdClass: 'align-middle' },
        { key: 'actions', label: ' ', tdClass: 'text-nowrap align-left' },
      ],
      urlParams: { issuerId: this.$route.params.idIssuer },
      allEnabled: true,
    };
  },

  methods: {
    ...mapActions('issuerEmailTemplates', [
      'getByName',
      'update',
      'delete',
      'enableAll',
      'disableAll',
    ]),

    openDeleteModal(item) {
      this.deleteItem = item;
      this.$refs.cpDeleteAlert.$refs.deleteAlert.open();
    },

    toEditModal({ name }) {
      this.getByName({
        issuerId: this.$route.params.idIssuer,
        name,
      }).then((data) => {
        this.emailData = data;
        this.$refs.CpIssuerEmail.$refs.issuerEmail.show();
      });
    },

    removeItem() {
      const { name } = this.deleteItem;
      this.delete({
        issuerId: this.$route.params.idIssuer,
        name,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.cpDeleteAlert.$refs.deleteAlert.close();
        setTimeout(() => { this.deleteItem = null; }, 1000);
      });
    },

    saveChanges(template) {
      Object.keys(template).forEach((key) => {
        if (template[key] === '') {
          template[key] = null;
        }
      });
      delete template.variableDescription;
      delete template.allowedVariables;

      this.update({
        issuerId: this.$route.params.idIssuer,
        template,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.CpIssuerEmail.$refs.issuerEmail.hide();
      });
    },

    updateEmailStatus(template) {
      const { enabled } = template;
      this.update({
        issuerId: this.$route.params.idIssuer,
        template: {
          ...template,
          enabled: !enabled,
        },
      }).then(() => {
        this.$refs.cpTable.updateTableData();
      });
    },

    updateAllEmailStatus() {
      this.allEnabled = !this.allEnabled;

      let enableDisableAll;
      if (this.allEnabled) {
        enableDisableAll = this.enableAll({
          issuerId: this.$route.params.idIssuer,
        });
      } else {
        enableDisableAll = this.disableAll({
          issuerId: this.$route.params.idIssuer,
        });
      }

      enableDisableAll.then(() => {
        this.$refs.cpTable.updateTableData();
      });
    },

    getActivationStatus(enabled) {
      return enabled ? this.$t('common.button.deactivate') : this.$t('common.button.activate');
    },

    getAllActivationStatus() {
      return this.allEnabled ? this.$t('common.button.deactivateAll') : this.$t('common.button.activateAll');
    },

    getActivationStatusLabel(enabled) {
      return enabled ? this.$t('issuerEmailTemplates.label.button.active') : this.$t('issuerEmailTemplates.label.button.inactive');
    },

    getActivationStatusColor(enabled) {
      return enabled ? 'success' : 'danger';
    },
  },
};
</script>

<style>
  .deactivate-all-btn {
    text-align: center;
    min-width: 156px;
  }
</style>

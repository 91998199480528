<template>
  <!-- eslint-disable vue/no-v-html -->
  <cp-general-modal
    ref="issuerEmail"
    :title="$t('issuerEmailTemplates.emailModal.title')"
    title-icon="ion ion-md-create"
    size="lg"
    :form-group-value="emailData"
    @onOk="handleSubmit"
  >
    <cp-input
      v-model="model.name"
      name="name"
      :label="$t('issuerEmailTemplates.emailModal.input.name')"
      disabled
    />

    <cp-input
      v-model="model.subject"
      name="subject"
      :label="$t('issuerEmailTemplates.emailModal.input.subject')"
    />

    <cp-input
      v-model="model.title"
      name="title"
      :label="$t('issuerEmailTemplates.emailModal.input.title')"
    />

    <cp-input
      v-model="model.from"
      name="from"
      :label="$t('issuerEmailTemplates.emailModal.input.from')"
      rules="required|email"
    />

    <cp-input
      v-model="model.allowedVariables"
      name="allowedVariables"
      :label="$t('issuerEmailTemplates.emailModal.input.allowedVariables')"
      disabled
    />

    <cp-textarea
      v-model="model.variableDescription"
      name="variableDescription"
      :label="$t('issuerEmailTemplates.emailModal.input.variableDescription')"
      :rows="5"
      disabled
    />
    <cp-textarea
      v-model="model.text"
      name="text"
      :label="$t('issuerEmailTemplates.emailModal.input.text')"
      :rows="10"
    />
    <cp-textarea
      v-model="model.content"
      name="content"
      :label="$t('issuerEmailTemplates.emailModal.input.content')"
      :rows="10"
    />

    <b-btn
      v-if="model.content"
      icon="ion ion-md-link"
      @click="htmlTempIsOpened = !htmlTempIsOpened"
    >
      <i :class="htmlTempIsOpened ? 'ion-md-eye-off' : 'ion-md-eye'" />
      {{ $t('issuerEmailTemplates.emailModal.button.preview') }}
    </b-btn>

    <div
      v-if="htmlTempIsOpened"
      v-dompurify-html="model.content"
      class="cp-html-content mt-3"
    />

    <div class="mt-3">
      <div class="cp-bg-grey">
        <div class="d-flex justify-content-between align-items-center  w-50 py-2 pl-2 pr-lg-5">
          <span>
            {{ $t('issuerEmailTemplates.emailModal.label.createdAt') }}
          </span>
          <span>
            <strong>
              {{ model.createdAt | dateFilter }}
            </strong>
          </span>
        </div>
      </div>

      <div class="cp-bg-grey mt-3">
        <div class="d-flex justify-content-between align-items-center w-50 py-2 pl-2 pr-lg-5">
          <span>
            {{ $t('issuerEmailTemplates.emailModal.label.updatedAt') }}
          </span>
          <span>
            <strong>
              {{ model.updatedAt | dateFilter }}
            </strong>
          </span>
        </div>
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpTextarea from '~/components/common/standalone-components/inputs/cp-textarea';

export default {
  name: 'IssuerEmailModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpTextarea,
  },
  props: {
    emailData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model: {},
      htmlTempIsOpened: false,
    };
  },
  watch: {
    emailData(val) {
      this.model = Object.assign({}, val);
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('onSubmit', this.model);
    },
  },
};
</script>

<style lang="scss">
  .cp-bg-grey {
    background-color: rgba(10, 23, 39, 0.025);
  }

  .cp-html-content {
    overflow: scroll;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: rgba(10, 23, 39, 0.025);
    min-height: 200px;
  }
</style>
